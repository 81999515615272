/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
    display: inline-block;
    width: 16px;
    height: 11px;
    background: url('../img/flags.png') no-repeat;
}


.flag.flag-hr {
    background-position: -16px -22px;
}

.flag.flag-sk {
    background-position: -32px -44px;
}

.flag.flag-dk {
    background-position: 0 -11px;
}

.flag.flag-at {
    background-position: 0 0;
}

.flag.flag-no {
    background-position: -16px -33px;
}

.flag.flag-nl {
    background-position: 0 -33px;
}

.flag.flag-es {
    background-position: -32px -11px;
}

.flag.flag-cz {
    background-position: -64px 0;
}

.flag.flag-de {
    background-position: -80px 0;
}

.flag.flag-lt {
    background-position: -64px -22px;
}

.flag.flag-lv {
    background-position: -80px -22px;
}

.flag.flag-gr {
    background-position: 0 -22px;
}

.flag.flag-ch {
    background-position: -48px 0;
}

.flag.flag-fr {
    background-position: -64px -11px;
}

.flag.flag-rs {
    background-position: -80px -33px;
}

.flag.flag-ua {
    background-position: -48px -44px;
}

.flag.flag-ro {
    background-position: -64px -33px;
}

.flag.flag-fi {
    background-position: -48px -11px;
}

.flag.flag-pt {
    background-position: -48px -33px;
}

.flag.flag-us {
    background-position: -64px -44px;
}

.flag.flag-gb {
    background-position: -80px -11px;
}

.flag.flag-pl {
    background-position: -32px -33px;
}

.flag.flag-hu {
    background-position: -32px -22px;
}

.flag.flag-it {
    background-position: -48px -22px;
}

.flag.flag-si {
    background-position: -16px -44px;
}

.flag.flag-be {
    background-position: -16px 0;
}

.flag.flag-bg {
    background-position: -32px 0;
}

.flag.flag-ee {
    background-position: -16px -11px;
}

.flag.flag-se {
    background-position: 0 -44px;
}
