/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  animation: App-logo-spin infinite 20s linear;*/
/*  height: 80px;*/
/*}*/

/*.App-header {*/
/*  background-color: #222;*/
/*  height: 150px;*/
/*  padding: 20px;*/
/*  color: white;*/
/*}*/

/*.App-title {*/
/*  font-size: 1.5em;*/
/*}*/


/*@keyframes App-logo-spin {*/
/*  from { transform: rotate(0deg); }*/
/*  to { transform: rotate(360deg); }*/
/*}*/


/*.logo {*/
/*  height: 32px;*/
/*  background: rgba(255,255,255,.2);*/
/*  margin: 16px;*/
/*}*/

/*.details-avatar {*/
/*  margin-bottom: -8px;*/
/*}*/

/*.browse-search .ant-row {*/
/*  margin-bottom: 6px !important;*/
/*}*/

/*.floatRight {*/
/*  float: right !important;*/
/*}*/

/*.logout {*/
/*  width: 120px;*/
/*  height: 31px;*/
/*  background: #333;*/
/*  border-radius: 6px;*/
/*  margin: 16px 24px 16px 0;*/
/*  float: left;*/
/*}*/

/*.apb {*/
/*  color: #c2cd23 !important;*/
/*}*/



/*#root {*/
/*  height: 100%;*/
/*}*/



.full-height {
  height: 100% !important;
}

html, body {
  height: 100% !important;
  margin: 0;
  padding: 0;
}

/*.Admin {*/
/*  background-color: #c2cd23 !important;*/
/*}*/

/*.User {*/

/*}*/

/*.selectTree {*/
/*  overflow-y: auto;*/
/*}*/

/*.lov-card {*/
/*  border: 1px solid #d9d9d9;*/
/*  border-radius: 4px;*/
/*}*/

/*#components-layout-demo-top-side-2 .logo {*/
/*  width: 120px;*/
/*  height: 31px;*/
/*  background: #333;*/
/*  border-radius: 6px;*/
/*  margin: 16px 28px 16px 0;*/
/*  float: left;*/
/*}*/

/*.steps-content {*/
/*  margin-top: 8px;*/
/*  border: 1px dashed #e9e9e9;*/
/*  border-radius: 6px;*/

/*  min-height: 250px;*/
/*  text-align: center;*/
/*}*/

/*.steps-action {*/
/*  margin-top: 24px;*/
/*  text-align: center;*/
/*}*/

/*.ant-tabs-tab {*/
/*  margin-right: 15px !important;*/
/*}*/

/*@media (max-width: 1280px) {*/
/*  .ant-tabs-tab-disabled {*/
/*    margin: 0 !important;*/
/*    display: none !important;*/
/*    width: 0 !important;*/
/*  }*/

/*  .ant-tabs-tab {*/
/*    margin-right: 5px !important;*/
/*  }*/

/*}*/

/*@media (max-width: 1000px) {*/
/*  .ant-tabs-tab {*/
/*    margin-right: 0 !important;*/
/*  }*/
/*}*/

/*@media (max-height: 1000px) {*/
/*  .selectTree {*/
/*    max-height: 550px;*/
/*  }*/
/*}*/

/*@media (max-height: 800px) {*/
/*  .selectTree {*/
/*    max-height: 400px;*/
/*  }*/
/*}*/

/*@media (max-height: 600px) {*/
/*  .selectTree {*/
/*    max-height: 300px;*/
/*  }*/
/*}*/

/*.ant-tabs-tab-disabled {*/
/*  width: 0 !important;*/
/*  margin-right: 0 !important;*/
/*  padding-right: 0 !important;*/
/*}*/

/*.vertical-center-modal {*/
/*  text-align: center;*/
/*  white-space: nowrap;*/
/*}*/

/*.vertical-center-modal:before {*/
/*  content: '';*/
/*  display: inline-block;*/
/*  height: 100%;*/
/*  vertical-align: middle;*/
/*  width: 0;*/
/*}*/

/*.vertical-center-modal .ant-modal {*/
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*  top: 0;*/
/*  text-align: left;*/
/*}*/

/*.logo-menu {*/
/*  background-color: transparent !important;*/
/*}*/

/*.search-bar {*/
/*  height: 46px !important;*/
/*  font-size: 15px !important;*/
/*}*/

/*.ant-table-thead tr th span {*/
/*  font-weight: bold;*/
/*}*/

/*.ant-tree-treenode-disabled .ant-tree-switcher-noop {*/
/*  display: none !important;*/
/*}*/

/*.ant-tree-treenode-disabled .ant-tree-node-content-wrapper {*/
/*  padding-left: 24px !important;*/
/*}*/

/*.title-field-input {*/
/*  padding-left: 5px !important;*/
/*  width: 70% !important;*/
/*  font-size: 14px !important;*/
/*  font-weight: 500 !important;*/
/*}*/

/*.title-field {*/
/*  display: block;*/
/*  width: 70% !important;*/
/*}*/

/*.title-field:hover {*/
/*  cursor: pointer;*/
/*}*/

/*.centered-item {*/
/*  text-align: center;*/
/*  cursor: default;*/
/*}*/
