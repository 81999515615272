.login-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  height: 100% !important;
  width: 100% !important;
  background-size: 100%;
  background: #f0f2f5 url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg") no-repeat 50%;
}
.login-container .login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-container .login-page .login-header {
  padding-top: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.login-container .login-page .login-header .login-subtitle {
  margin-top: 12px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.login-container .login-page .login-form {
  width: 300px;
  padding: 24px 0;
}
.login-container .login-page .login-form .actions {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}
.login-container .login-page .login-form .login-form-button {
  width: 100%;
}
.dcr-group-detail-container {
  padding: 20px;
}
.imf-row-container .label {
  text-align: right;
  padding-right: 14px;
}
.imf-row-container .label:after {
  content: ':';
}
.dcr-source-detail-container {
  padding: 20px;
}
.dcr-account-detail-container {
  padding: 20px;
}
.layout-container {
  min-height: 100vh;
}
.layout-container .logo {
  float: left;
  height: 100%;
}
.layout-container .logo img {
  height: 65%;
  padding-right: 20px;
}
